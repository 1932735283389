* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 60px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --cor-primaria:#2196f3;
  --cor-secundaria:#FFFFFF;
}

/* HOTSITE */

.h-main-container {
  padding-top: 60px;

}

.h-header-container {
  width: 100%;
  height: 60px;
  position: fixed;
  background-color: var(--cor-primaria);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  z-index: 99999;
}

.h-header-container>img {
  width: 100px;
  object-fit: contain;
}

.h-header-container>nav {
  display: flex;
  gap: 30px;
  align-items: center;
}

.h-header-container>nav>a {
  font-size: 15px;
  color: white;
  text-decoration: none;
}

.h-header-container>nav>button {
  font-size: 20px;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: none;
}

.h-header-container>nav>a:hover {
  text-decoration: underline;
}

.h-slide-img {
  width: 100%;
}

.h-sobre-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 60px 30px;
  background-color: var(--cor-primaria);
  margin-top: -5px;
}

.h-sobre-container>div {
  width: 30%;
  padding: 50px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  color: white;
}

.h-sobre-container>h2 {
  font-size: 25px;
  text-decoration: underline;
  text-decoration-color: var(--cor-secundaria);
}

.h-sobre-container>p {
  font-size: 20px;
}

.h-func-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.h-func-container>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
}

.h-func-container>div>img {
  width: 400px;
  z-index: 99;
  /* background-color: lightgray; */
  border-radius: 500px;
  object-fit: cover;
  /* box-shadow: 0 5px 5px rgba(0,0,0,0.5) */
}

.h-func-container>div>img:nth-child(1) {
  margin-bottom: -30px;
}

.h-func-container>div>img:nth-child(2) {
  margin-top: -30px;
}

.h-func-container>div>p {
  width: 50%;
  font-size: 25px;
}

.h-func-container>div:nth-child(2)>p {
  text-align: center;
  margin: 0 auto;
}

.h-depo-container {
  width: 100%;
  background-color: var(--cor-primaria);
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 50px 20px;
}

.h-depo-container>div {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  background-color: white;
  border-radius: 5px;
  padding: 80px;
  justify-content: flex-start;
}

.h-depo-container>div>img {
  width: 100px;
  height: 100px;
  border-radius: 500px;
}

.h-depo-container>div>div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.h-depo-container>div>div>h2 {
  font-size: 20px;
  font-style: italic;
}

.h-depo-container>div>div>p {
  font-size: 17px;
  
}

.h-faq-container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.h-footer-container {
  width: 100%;
  padding: 10px;
  background-color: var(--cor-primaria);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.h-footer-container>img {
  width: 200px;
  object-fit: contain;
}

.h-footer-container>section {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.h-footer-container>section>div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.h-footer-container>section>div>a {
  font-size: 17px;
  color: white;
}


.h-titulo-underline{
  font-size: 25px;
  text-decoration: underline;
  text-decoration-color: var(--cor-primaria);
}

.h-texto-normal{
  font-size: 20px;
text-align: left;
color: #656464;
}

.h-politica-privacidade-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  gap: 20px;
}

.h-titulo-paragrafo{
  text-align: left;
font-size: 35;
font-weight: bold;
margin: 30px 0px 3px 0px;
}

@media screen and (max-width: 768px) {
  .h-header-container>nav>a {
    display: none;
  }

  .h-header-container>nav>button {
    display: block;
  }

  .h-sobre-container {
    flex-wrap: wrap;
    padding: 10px;
  }


  .h-sobre-container>div {
    width: 100%;
  }

  .h-func-container>div {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .h-func-container>div>img {
    width: 100%;
  }

  .h-func-container>div {
    flex-direction: column;
    text-align: center;
  }

  .h-func-container>div>p {
    width: 100%;
    padding: 30px 0;
  }

  .h-func-container>div:nth-child(2)>p {
    margin: 0;
  }

  .h-depo-container {
    flex-direction: column;
  }

  .h-depo-container>div {
    width: 100%;
  }

  .h-faq-container {
    padding: 30px 0;
  }
  
  .h-faq-container>h2 {
    text-align: center;
  }

  .h-footer-container>section {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
  }
}